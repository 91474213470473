import React, { useState, useEffect, useRef } from "react";
import ReactFullpage from "@fullpage/react-fullpage";
import { useMediaQuery } from "react-responsive";
import Particles from "react-particles-js";
import Typewriter from "typewriter-effect";
import Knight from "./Knight";
import Laptop from "./Laptop";
import { Dialog, Drawer } from "@material-ui/core";
import { ReactComponent as Head } from "./images/head.svg";
import { ReactComponent as Twitter } from "./images/icons/twitter.svg";
import { ReactComponent as Linkedin } from "./images/icons/linkedin.svg";
import { ReactComponent as Github } from "./images/icons/github.svg";
import { ReactComponent as Indiehackers } from "./images/icons/indiehackers.svg";
import { ReactComponent as Email } from "./images/icons/email.svg";
import { ReactComponent as BackButton } from "./images/back-button.svg";
import { ReactComponent as CopyPageImage } from "./images/copypage-image.svg";
import { ReactComponent as MakersLeagueImage } from "./images/makers-league-image.svg";
import { ReactComponent as UICoachLogo } from "./images/uicoach-logo.svg";
import { ReactComponent as MakerLeagueLogo } from "./images/makersleague-logo.svg";
import { ReactComponent as NerofitLogo } from "./images/nerofit-logo.svg";
import { ReactComponent as CopyPageLogo } from "./images/copypage-logo.svg";
import { ReactComponent as LinkIcon } from "./images/icons/link-icon.svg";
import { ReactComponent as CVIcon } from "./images/icons/cv.svg";
import nerofitImage from "./images/nerofit-image.jpg";
// particles
import react from "./images/particles/react-logo.svg";
import php from "./images/particles/php-logo.svg";
import wordpress from "./images/particles/wordpress-logo.svg";
import nodejs from "./images/particles/nodejs-logo.svg";
import javascript from "./images/particles/javascript-logo.svg";
import java from "./images/particles/java-logo.svg";
import html from "./images/particles/html5-logo.svg";
import github from "./images/particles/github-logo.svg";
import css from "./images/particles/css-logo.svg";
import bootstrap from "./images/particles/bootstrap-logo.svg";
import xd from "./images/particles/adobexd-logo.svg";
import afftereffects from "./images/particles/aftereffects-logo.svg";
import figma from "./images/particles/figma-logo.svg";
import illustrator from "./images/particles/illustrator-logo.svg";
import invision from "./images/particles/invision -logo.svg";
import photoshop from "./images/particles/photoshop-logo.svg";
import python from "./images/particles/python-logo.svg";
import aws from "./images/particles/aws-logo.svg";
import sass from "./images/particles/sass-logo.svg";
import laravel from "./images/particles/laravel -logo.svg";
import mysql from "./images/particles/mysql-logo.svg";
import materialize from "./images/particles/materialize-logo.svg";
import android from "./images/particles/android -logo.svg";
import angular from "./images/particles/angular-logo.svg";
import vue from "./images/particles/vue-logo.svg";
import tailwind from "./images/tailwind-logo.svg";
import firebase from "./images/firebase-logo.svg";
import openAi from "./images/openai-logo.svg";

export default function Content() {
  const [copyPageDialog, setCopyPageDialog] = useState(20);
  const [uicoachDialog, setUicoachDialog] = useState(20);
  const [makersLeagueDialog, setMakersLeagueDialog] = useState(20);
  const [nerofitDialog, setNerofitDialog] = useState(20);
  useEffect(() => {}, [
    copyPageDialog,
    uicoachDialog,
    makersLeagueDialog,
    nerofitDialog,
  ]);
  return (
    <div className=" max-w-screen overflow-y-hidden overflow-x-hidden">
      <div className="absolute max-w-screen z-30 w-full h-full overflow-y-scroll overflow-x-hidden ">
        <div className=" section border-b-4 border-pal-green container col-center relative  justify-center">
          <Head
            style={{
              position: "absolute",
              top: "30%",
              left: "50%",
              transform: "translateX(-50%)",
            }}
            className=" w-1/2 h-1/2 lg:w-1/4 lg:h-1/4 self-center "
          />
          <div
            style={
              {
                // position: "absolute",
                // bottom: "5%",
                // left: "5%",
                // transform: "translateX(-50%)",
              }
            }
            className="row-center lg:col-center absolute top-10  lg:top-auto lg:bottom-10 lg:left-10 "
          >
            <a href="https://twitter.com/nero_awab" target="__blank">
              <Twitter className="w-12 h-12 mb-2 mr-2" />
            </a>

            <a href="https://github.com/awabxnero" target="__blank">
              <Github className="w-12 h-12 mb-2 mr-2" />
            </a>
            <a href="https://www.indiehackers.com/Nero_ceo" target="__blank">
              <Indiehackers className="w-12 h-12 mb-2 mr-2" />
            </a>
            <button
              onClick={() => {
                window.prompt(":)", "nero@awab.dev");
              }}
            >
              <Email className="w-12 h-12 mb-2 mr-2" />
            </button>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/portfolio-cf8e7.appspot.com/o/cv3.6_compressed.pdf?alt=media&token=f91a3de4-f329-4a53-9c6a-d4fcace8126e"
              target="__blank"
            >
              <CVIcon className="w-12 h-12 mb-2 " />
            </a>
          </div>
          <Particles
            className=" container w-screen h-screen particles "
            params={{
              particles: {
                line_linked: {
                  enable: false,
                },
                move: {
                  out_mode: "out",
                  direction: "top",
                },
                shape: {
                  type: "images",
                  images: [
                    { src: xd, height: 20, width: 20 },
                    { src: afftereffects, height: 20, width: 20 },
                    { src: figma, height: 30, width: 20 },
                    { src: illustrator, height: 20, width: 20 },
                    { src: invision, height: 20, width: 20 },
                    { src: photoshop, height: 20, width: 20 },
                    { src: react, height: 20, width: 20 },
                    { src: react, height: 20, width: 20 },
                    { src: php, height: 20, width: 30 },
                    { src: wordpress, height: 20, width: 20 },
                    { src: nodejs, height: 20, width: 20 },
                    { src: javascript, height: 20, width: 20 },
                    { src: java, height: 20, width: 20 },
                    { src: html, height: 20, width: 20 },
                    { src: android, height: 25, width: 20 },
                    { src: angular, height: 20, width: 20 },
                    { src: vue, height: 20, width: 20 },
                    { src: github, height: 20, width: 20 },
                    { src: bootstrap, height: 20, width: 20 },
                    { src: css, height: 20, width: 20 },
                    { src: python, height: 20, width: 20 },
                    { src: aws, height: 20, width: 30 },
                    { src: sass, height: 20, width: 20 },
                    { src: mysql, height: 20, width: 20 },
                    { src: laravel, height: 20, width: 20 },
                    { src: materialize, height: 20, width: 30 },
                    { src: tailwind, height: 20, width: 30 },
                    { src: firebase, height: 30, width: 20 },
                  ],
                },
                color: {
                  value: "#00ff00",
                },
                opacity: {
                  value: 0.6,
                },
                size: {
                  value: 25,
                  random: false,
                  anim: {
                    enable: true,
                    speed: 4,
                    size_min: 10,
                    sync: false,
                  },
                },
              },
              interactivity: {
                events: {
                  onhover: {
                    enable: true,
                    mode: "bubble",
                  },
                },
              },
              retina_detect: false,
            }}
          />
        </div>
        <div className=" section container h-auto lg:h-full w-full col-center">
          <h1
            style={{ textAlign: "center" }}
            className="mt-10 text-3xl text-center  blue-underline"
          >
            About
          </h1>
          <div className="flex flex-col lg:flex-row items-end w-full h-full">
            <div className="flex flex-col text-left w-3/4 lg:w-1/2 mt-40 ml-10 self-start">
              <div className="flex flex-col text-left">
                <h2 className="text-xl text-pal-green">Personality</h2>
                <p>
                  The best formal way to describe my personality to a complete
                  stranger is the Myers-Briggs personality test. The test
                  classified my personality type as{" "}
                  <span className="text-pal-blue">(ENFJ-A)</span> also known as
                  "The Protagonist" Protagonists are natural-born leaders
                  passionate and charismatic. Protagonists take a great deal of
                  pride in guiding others to work together to improve themselves
                  and the community around them. If i wanted to add a more
                  personal touch to this accurate description, I would say that
                  i have an unhealthy obsession with success and becoming the
                  founder of unicorn startup.
                </p>
                <a
                  className="text-pal-green  text-xs lg:text-sm"
                  href="https://www.16personalities.com/enfj-personality"
                  target="__blank"
                >
                  {`< source: www.16personalities.com >`}
                </a>
              </div>
              <div className="flex flex-col text-left">
                <h2 className="text-xl text-pal-green mt-10">Education</h2>
                <p>
                  I'm currently doing a software engineering bachelors degree in
                  University of Malaya which is ranked:
                  <br />
                  <span className="text-pal-blue">#1</span> in Malaysia and
                  <br />
                  <span className="text-pal-blue">#59</span> Worldwide
                </p>
                <a
                  className="text-pal-green text-xs lg:text-sm"
                  href="https://www.topuniversities.com/universities/universiti-malaya-um"
                  target="__blank"
                >
                  {`< source: www.topuniversities.com >`}
                </a>
              </div>
            </div>
            <div className=" pt-40 lg:pt-0 lg:h-full w-full lg:w-1/2 hidden lg:flex col-center justify-end  bottom-0 right-0">
              <p className="hidden  text-pal-green text-xs lg:text-sm mb-20   row-center">
                <span className="mr-1 ">{`< My name is Nero and i'm a `}</span>
                <Typewriter
                  onInit={(typewriter) => {
                    typewriter
                      .pauseFor(1200)
                      .changeDelay(10)
                      .changeDeleteSpeed(10)
                      .typeString("Software engineer")
                      .pauseFor(1500)
                      .deleteChars(17)
                      .typeString("UI/UX designer")
                      .pauseFor(1500)
                      .deleteChars(14)
                      .typeString("Digital marketer")
                      .pauseFor(1500)
                      .deleteChars(16)
                      .typeString("Maker")
                      .pauseFor(1500)
                      .deleteChars(5)
                      .start();
                  }}
                  options={{
                    autoStart: true,
                    loop: true,
                  }}
                />
                {` >`}
              </p>
              <Knight className=" w-full h-full" />
            </div>
          </div>
        </div>
        <div className=" section container  px-5  col-center border-4 border-pal-purple">
          <h1
            style={{ textAlign: "center" }}
            className="mt-10 text-3xl text-center  blue-underline "
          >
            Skills
          </h1>
          <div className="flex flex-col lg:flex-row items-center justify-between">
            <div className="flex flex-col text-left w-full lg:w-1/2 mt-40 mb-40  pl-5 self-start">
              <p>
                I've always had a passion for marketing ever since i was young.
                I started a fitness clothing brand as my first business venture
                which gave me alot of experince in{" "}
                <span className="text-pal-blue">*</span>branding and marketing.
                Shortly after i took a web development class in university, i
                fell in love with web programming because it gave me the ability
                to execute any software business ideas i had in mind. Ever
                since, i've split my time between learning{" "}
                <span className="text-pal-blue">*</span>UI/UX design, graphic
                design in addition to front and backend web development, the
                essential tools that take a project from a concept to
                deployment. My main area of expertise is the MERN stack
                <br /> <span className="text-pal-blue">*</span>MongoDB <br />
                <span className="text-pal-blue">*</span>Express JS <br />
                <span className="text-pal-blue">*</span>React JS <br />
                <span className="text-pal-blue">*</span>Node JS <br />
                In addition to that, i enjoy using{" "}
                <span className="text-pal-blue">*</span>CSS as well as material
                design frameworks such as Bootstrap, Materialize and Material UI
                alongside javascript animation libraries such as Greensock to
                create smooth and stunning layouts.
              </p>
            </div>
            <Laptop className=" w-full h-full" />
          </div>
        </div>
        <div className=" section container col-center">
          <h1
            style={{ textAlign: "center" }}
            className="mt-10 text-3xl text-center  blue-underline"
          >
            Ventures
          </h1>
          <div className=" w-full pt-20 px-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-14 lg:gap-y-10 lg:gap-x-16 lg:px-20 text-black">
            {/* TODO make carousel */}
            <button
              onClick={() => setCopyPageDialog(40)}
              style={{
                boxShadow: "-5px 5px 0px #A9FF03",
                borderColor: "#C58FFF",
              }}
              className="p-8 flex flex-col items-center text-left text-white bg-black hover:bg-pal-blue hover:text-black border-4 border-solid "
            >
              <CopyPageLogo className="w-12 h-12 mb-5" />
              <h3 className=" text-xl mb-2 ">Copy Page</h3>
            </button>
            <button
              onClick={() => setUicoachDialog(40)}
              style={{
                boxShadow: "-5px 5px 0px #A9FF03",
                borderColor: "#C58FFF",
              }}
              className="p-8 flex flex-col items-center text-left text-white bg-black hover:bg-pal-blue hover:text-black border-4 border-solid "
            >
              <UICoachLogo className="w-12 h-12 mb-5" />
              <h3 className=" text-xl mb-2 ">UI Coach</h3>
            </button>
            <button
              onClick={() => setMakersLeagueDialog(40)}
              style={{
                boxShadow: "-5px 5px 0px #A9FF03",
                borderColor: "#C58FFF",
              }}
              className="p-8 flex flex-col items-center text-left text-white bg-black hover:bg-pal-blue hover:text-black border-4 border-solid "
            >
              <MakerLeagueLogo className="w-12 h-12 mb-5" />
              <h3 className=" text-xl mb-2 ">Makers League</h3>
            </button>
            <button
              onClick={() => setNerofitDialog(40)}
              style={{
                boxShadow: "-5px 5px 0px #A9FF03",
                borderColor: "#C58FFF",
              }}
              className="p-8 flex flex-col items-center text-left text-white bg-black hover:bg-pal-blue hover:text-black border-4 border-solid "
            >
              <NerofitLogo className="w-14 h-14 mb-5" />
              <h3 className=" text-xl mb-2 ">Nero-Fit Apparel</h3>
            </button>
          </div>
        </div>
        <div className=" section container col-center justify-start py-10">
          <h1
            style={{ textAlign: "center" }}
            className="mt-10 lg:mt-0 text-center text-3xl blue-underline mb-20 lg:mb-60"
          >
            Contact
          </h1>
          <div className="flex flex-col lg:flex-row items-center max-w-screen h-auto justify-around px-10  w-full">
            <a href="https://twitter.com/nero_awab" target="__blank">
              <Twitter className="w-20 h-20 lg:w-40 lg:h-40 mb-5 lg:mb-0" />
            </a>

            <a href="https://github.com/awabxnero" target="__blank">
              <Github className="w-20 h-20 lg:w-40 lg:h-40 mb-5" />
            </a>
            <a href="https://www.indiehackers.com/Nero_ceo" target="__blank">
              <Indiehackers className="w-20 h-20 lg:w-40 lg:h-40 mb-5 lg:mb-0" />
            </a>
            <button
              onClick={() => {
                window.prompt(":)", "nero@awab.dev");
              }}
            >
              <Email className="w-20 h-20 lg:w-40 lg:h-40 mb-5" />
            </button>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/portfolio-cf8e7.appspot.com/o/cv3.6_compressed.pdf?alt=media&token=f91a3de4-f329-4a53-9c6a-d4fcace8126e"
              target="__blank"
            >
              <CVIcon className="w-20 h-20 lg:w-40 lg:h-40 mb-5 lg:mb-0" />
            </a>
          </div>
        </div>
      </div>
      {copyPageDialog === 40 ? (
        <div
          className={`absolute z-${copyPageDialog} w-full h-full ${
            copyPageDialog !== 40 ? "hidden" : "grid"
          } overflow-y-scroll`}
        >
          <div className="bg-pal-bg text-white lg:max-h-screen lg:overflow-hidden w-full h-full grid lg:flex lg:flex-row">
            <div className="w-full grid  items-start   h-full p-8 lg:p-10">
              <BackButton
                onClick={() => {
                  setCopyPageDialog(10);
                }}
                className=" w-12 h-12 lg:w-16 lg:h-16 cursor-pointer"
              />
              <div className="h-full w-full pt-20">
                <CopyPageLogo className="w-32 h-32 mb-10" />
                <h3 className=" text-xl mb-2 text-pal-green">Copy Page</h3>
                <p>
                  Copy Page is an AI-powered copywriter for that enables you to
                  create great content without the need for a professional
                  copywriter.
                </p>
                <p className="mt-5 mb-2">Built with:</p>
                <div className="w-full row-center mb-10">
                  <img
                    title="React JS"
                    src={react}
                    className="w-10 h-10 mr-5"
                    alt=""
                  />
                  <img
                    title="Tailwind CSS"
                    src={tailwind}
                    className="w-10 h-10 mr-5"
                    alt=""
                  />
                  <img
                    title="Firebase"
                    src={firebase}
                    className="w-10 h-10 mr-5"
                    alt=""
                  />
                  <img
                    title="GPT3 from OpenAI"
                    src={openAi}
                    className="w-16 h-16 mr-5"
                    alt=""
                  />
                </div>
                <a
                  className="button w-min row-center"
                  href="https://copypage.io/"
                  target="__blank"
                >
                  <span className=" whitespace-nowrap">Check it out</span>{" "}
                  <LinkIcon className="w-5 h-5 ml-2" />
                </a>
              </div>
            </div>
            <div className="w-full h-full flex justify-end items-end flex-col  bg-black">
              <CopyPageImage className=" w-full max-w-screen " />
            </div>
          </div>
        </div>
      ) : null}

      {uicoachDialog === 40 ? (
        <div
          className={`absolute z-${uicoachDialog} w-full h-full ${
            uicoachDialog !== 40 ? "hidden" : "grid"
          } overflow-y-scroll`}
        >
          <div className="bg-pal-bg text-white w-full h-full grid lg:flex lg:flex-row">
            <div className="w-full grid items-start lg:grid  h-full p-10">
              <BackButton
                onClick={() => setUicoachDialog(10)}
                className="w-12 h-12 lg:w-16 lg:h-16 cursor-pointer"
              />
              <div className="h-full w-full pt-20">
                <UICoachLogo className="w-32 h-32 mb-10" />
                <h3 className=" text-xl mb-2 text-pal-green">UI Coach</h3>
                <p>
                  UI Coach helps you become a better UI designer by generating
                  UI design challenges which include: Project ideas, Color
                  palettes, Illustrations libraries
                </p>
                <p className="mt-5 mb-2">Built with:</p>
                <div className="w-full row-center mb-10">
                  <img
                    title="React JS"
                    src={react}
                    className="w-10 h-10 mr-5"
                    alt=""
                  />
                  <img
                    title="Tailwind CSS"
                    src={tailwind}
                    className="w-10 h-10 mr-5"
                    alt=""
                  />
                  <img
                    title="Firebase"
                    src={firebase}
                    className="w-10 h-10 mr-5"
                    alt=""
                  />
                </div>
                <a
                  className="button w-min row-center"
                  href="https://uicoach.io/"
                  target="__blank"
                >
                  <span className=" whitespace-nowrap">Check it out</span>{" "}
                  <LinkIcon className="w-5 h-5 ml-2" />
                </a>
              </div>
            </div>
            <div
              style={{ background: "#FFFF66" }}
              className="w-full h-full col-center justify-center flex-col items-end "
            >
              <UICoachLogo className="w-1/4 h-1/4" />
            </div>
          </div>
        </div>
      ) : null}

      {makersLeagueDialog === 40 ? (
        <div
          className={`absolute z-${makersLeagueDialog} w-full h-full ${
            makersLeagueDialog !== 40 ? "hidden" : "grid"
          } overflow-y-scroll`}
        >
          <div className="bg-pal-bg text-white w-full h-full grid lg:flex lg:flex-row">
            <div className="w-full grid items-start lg:grid  h-full p-10">
              <BackButton
                onClick={() => setMakersLeagueDialog(10)}
                className="w-12 h-12 lg:w-16 lg:h-16 cursor-pointer"
              />

              <div className="h-full w-full pt-20">
                <MakerLeagueLogo className="w-32 h-32 mb-10" />
                <h3 className=" text-xl mb-2 text-pal-green">Makers League</h3>
                <p>
                  Makers league is a a platform that connects developers,
                  designers, marketers or anyone who's interested in building
                  tech startups and digital products.
                </p>
                <p className="mt-5 mb-2">Built with:</p>
                <div className="w-full row-center mb-10">
                  <img
                    title="React JS"
                    src={react}
                    className="w-10 h-10 mr-5"
                    alt=""
                  />
                  <img
                    title="CSS"
                    src={css}
                    className="w-10 h-10 mr-5"
                    alt=""
                  />
                  <img
                    title="Firebase"
                    src={firebase}
                    className="w-10 h-10 mr-5"
                    alt=""
                  />
                </div>
                <a
                  className="button w-min row-center"
                  href="https://makersleague.co/"
                  target="__blank"
                >
                  <span className=" whitespace-nowrap">Check it out</span>{" "}
                  <LinkIcon className="w-5 h-5 ml-2" />
                </a>
              </div>
            </div>
            <div className=" h-full w-auto max-h-screen bg-black">
              <MakersLeagueImage className="h-full  w-auto max-h-screen max-w-screen" />
            </div>
          </div>
        </div>
      ) : null}

      {nerofitDialog === 40 ? (
        <div
          className={`absolute z-${nerofitDialog} w-full h-full ${
            nerofitDialog !== 40 ? "hidden" : "grid"
          } overflow-y-scroll`}
        >
          <div className="bg-pal-bg text-white w-full h-full grid lg:flex lg:flex-row">
            <div className="w-full grid items-start lg:grid h-full p-10">
              <BackButton
                onClick={() => setNerofitDialog(10)}
                className="w-12 h-12 lg:w-16 lg:h-16 cursor-pointer"
              />
              <div className="h-full w-full pt-20">
                <NerofitLogo className="w-32 h-32 mb-10" />
                <h3 className=" text-xl mb-2 text-pal-green">
                  Nero-Fit Apparel
                </h3>
                <p className="mb-10">
                  Nero-fit apparel is a fitness clothing brand that focuses on
                  building a healthy, strong community of people that radiate
                  confidence.
                </p>
                <a
                  className="button w-min row-center"
                  href="https://www.instagram.com/nerofitapparel/?hl=en"
                  target="__blank"
                >
                  <span className=" whitespace-nowrap">Check it out</span>{" "}
                  <LinkIcon className="w-5 h-5 ml-2" />
                </a>
              </div>
            </div>
            <div className="w-full h-full flex justify-end flex-col items-end bg-black">
              <img
                src={nerofitImage}
                className="h-full w-full max-w-screen"
                alt=""
              />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
